import React, { Component } from 'react';


class About_Content extends Component {
   
    render() { 
        return (
            <section id="about-us-inner" className="index-2">
        <div className="container">
          <h2 className="d-none">Heading</h2>
          <div className="col-lg-12 col-md-12 col-xs-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 img-area"> <img className="img-area" src="img/abt1.jpg" alt="About" /> </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-area">
                <div className="about-heading">ABOUT OUR COMPANY</div>
                <p class="text-justify">Homecare Intl with its professional teams has served mail order/catalogue houses, department stores, retail outlets and importers in USA, Europe Presently it offers a unique combination of essential services to market, promote, and facilitate the exports of Pakistani home textiles and garments to their Principals, which include sourcing suppliers, arranging meetings, communicating between Buyer/supplier, negotiating orders, and controlling Buyer’s specification, quality and delivery dates.</p>
                <div className="about-heading">FUNCTION OF THE COMPANY</div>
                <p class="text-justify">We have deep business interaction with manufactures in major cities of Pakistan and outside Pakistan so we are quite flexible and confident to do any kind of textile products within agreed time frame and quality entirely as per given order specifications.
Homecare is working as Multi-Functional company in the field of all kind of Yarn, Fabrics, Home textiles and Textile Apparels. The Chief Executive of the Company has 25 Years Of Textile Experience on its back in dealing all categories of textile products as given details below:</p>

<div className="about-heading">DELIVERIES/ LEAD TIME</div>
<p class="text-justify">Our normal lead time for garments are 90 days and for fabrics and home textiles 60 days but in special case on buyers demands. we can perform extraordinary in 30-45 days also just to save our valued buyer from the burden of extra air freight cost.</p> 




              </div>
              <div className="about-heading">QUOTATIONS / SAMPLES </div>
              <p class="text-justify">We will be pleased to receive your kind specific order inquiries in detail to provide you with our the best possible prices and counter samples in return on your kind desire.
Our company has divided in two textile divisions , operating separately under the well educated and experienced production and quality control staff.
We once again assure you that please at least give us an chance to serve your good selves, We have confident that you will find our self up to your entire desire satisfaction regarding best competitive prices, on time prompt deliveries and quality as per your desire standards and as given specific order details and manual.</p> 
            </div>
          </div>
        </div>
      </section>

          );
    }
}
 
export default About_Content;