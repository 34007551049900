import React from "react";
import logo from '../img/logo-6.png'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import Test3 from './test3'
function refreshPage(){ 
  window.location.reload(); 
}



class box3 extends React.Component {
  render() {
    return (
      <div className="site-section bg-light">
      <div className="container">
        <div className="row align-items-stretch section-overlap">
          <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
            <div className="banner-wrap bg-white h-100">
              <a href="#" className="h-100">
                <h4>We Manufacture</h4>
                <p>
                  
                  <strong><br/>Submit a manufacturer to be featured with a free video and we'll send you a free sample to find our what you need.</strong>
                </p>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
            <div className="banner-wrap  h-100">
              <a href="#" className="h-100">
                <h4 className="text-white"><b>We Design Fabric</b></h4>
                <p>
                  
                  <strong className="text-white"><br/>Choose from many fabrics and design your . Create and print only the fabric you need with no minimum order.</strong>
                </p>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
            <div className="banner-wrap bg-white  h-100">
              <a href="#" className="h-100">
                <h4>We Engineer</h4>
                <p>
                  
                  <strong><br/>We carry an enormous inventory of all the things you need to complete your project: notions, patterns and trims.</strong>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    );
  }
}

export default box3;
