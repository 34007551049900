import React, { Component } from 'react';
import axios from 'axios';
import { exists } from 'fs';
function myFunction() {
  document.getElementById("myForm").reset();
}
function validateForm1() {
  var a = document.forms["myForm"]["full_name"].value;
  var b = document.forms["myForm"]["email"].value;
  var c = document.forms["myForm"]["phone"].value;
  var d = document.forms["myForm"]["subject"].value;
  var e = document.forms["myForm"]["message"].value;
  if (a == "") {
    alert("Name must be filled out");
    return false;
  }
  
   if (b == "") {
    alert("Email must be filled out");
    return false;
  }
  if (c == "") {
    alert("Phone must be filled out");
    return false;
  }
  if (d == "") {
    alert("Subject must be filled out");
    return false;
  }
  if (e == "") {
    alert("Message must be filled out");
    return false;
  }else{
    return true;
  }


}

class Contact extends Component {
  constructor() {
    super();
    this.state = {
     //site_url:'http://localhost/wiztest/index.php',
     error: {},
     btnloading: false,
     full_name:'',
     email: '',
     phone:'',
     subject:'',
     message:''
    }
    this.onChange = this.onChange.bind(this);
  }
  componentWillMount(){
      this.setState({
          //site_url: window['site_url']
      });
  }
  componentDidMount(){

  }
 

onChange(e){
this.setState({[e.target.name]: e.target.value});
}




submitBiodata(e)
{
  if(validateForm1()){
e.preventDefault();

this.setState({btnloading:true});

const formData = new FormData();
formData.append('full_name',this.state.full_name);
formData.append('email',this.state.email);
formData.append('phone',this.state.phone);
formData.append('subject',this.state.subject);
formData.append('message',this.state.message);



//axios.post('http://localhost/wiztest/composer/contactus_contact.php',formData).then(res => {});


axios.post(this.state.site_url+ '/welcome/contact',formData).then(res => {
    const response =res.data;

    if(response.status){
        this.setState({error:{}});
        alert(response.message);

        this.setState({
          full_name:'',
          email: '',
          phone:'',
          subject:'',
          message:''
        });
    }else{
       
        this.setState({error:response.error});
        alert(response.message);
    }

}).then(() =>{
    this.setState({btnloading:false});
}).catch(error =>{
    alert(error.response.status +':'+ error.response.statusText);
  
});

myFunction();
}
}


    render() { 
        return (
            <section id="contact-us">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                      <h3>Contact Info</h3>
                      <p class="text-justify">Our professional team is here for you 24/7, so please do not hesitate to contact us if you have any questions, problems or maybe suggestion. We would love to hear from you.

You can reach us on email address by filling out the form on the right, or meet with us in person on some of the addresses below. Just make sure you make an appointment first. Contact information is shown below.</p>
                      <div className="separator left-align ">
                        <ul>
                          <li />
                          <li />
                          <li />
                        </ul>
                      </div>
                      <div className="info">
                        <ul>
                          <li>
                            <div className="icon">
                              <i className="flat flaticon-phone-receiver" />
                            </div>
                            <div className="content">
                              <div className="label">Phone No.</div>
                              Pakistan Office : <span class="value"><br />  +92-41-2421546 <br/>
+92-304-7133321
  </span> <br /> 
                              Sweden Office : <span class="value"><br />  +46-763-105997  </span><br/>
                              Saudia Arabia Office : <span class="value"><br /> +966-59-5035572  </span>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <i className="flat flaticon-mail-black-envelope-symbol" />
                            </div>
                            <div className="content">
                              <div className="label">Email Id</div>
                            Email Us : <span class="value"><a href="javascript:void(0)" title=""> <br/>info@homecareintl.com</a></span><br />
                              </div>
                          </li>
                          <li>
                            <div className="icon">
                              <i className="flat flaticon-facebook-placeholder-for-locate-places-on-maps" />
                            </div>
                            <div className="content">
                              <div className="label">Address</div>
                              Offices : <span class="value"> <br /> <b>Pakistan Office:</b><br /> Plot #260-D Block Small Industrial Estate, Faisalabad - Pakistan.</span>
                              <span class="value "> <br /><br/> <b>Sweden Office:</b> <br />Nytorgsbacken house#55 Helsingborg Sweden, Post Code:25245.</span>
                              <span class="value"> <br /><br /> <b>Saudia Arabia Office:</b> <br />Industrial City, Phase-11, Jeddah, Saudi Arabia.</span>
                            
                            
                            
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12 ex">
                    
                      <div className="form-header text-center">
                        <h4>Let’s Get In Touch!</h4>
                        <div className="separator">
                          <ul>
                            <li />
                            <li />
                            <li />
                          </ul> 
                        </div>
                        <p>It would be great to hear from you! If you got any questions</p>
                      </div>
                      <div className="contact-form">
                        <form  name="myForm" action="" method="POST" id="myForm" onsubmit="return validateForm1()">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <div className="form-group">
                                <input defaultValue type="text" placeholder="Full Name" value={this.setState.full_name} onChange={this.onChange} name="full_name" defaultValue={""} required/>
                              </div>
                              <div className="form-group">
                                <input defaultValue type="text" placeholder="Email" value={this.setState.email} onChange={this.onChange} name="email" defaultValue={""} required />
                              </div>
                              <div className="form-group">
                                <input defaultValue type="text" placeholder="Phone No." value={this.setState.phone} onChange={this.onChange} name="phone" defaultValue={""} required />
                              </div>
                              <div className="form-group">
                                <input defaultValue type="text" placeholder="Subject" value={this.setState.subject} onChange={this.onChange} name="subject" defaultValue={""}  required/>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                              <textarea placeholder="Message" value={this.setState.message} onChange={this.onChange} name="message" defaultValue={""} required />
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group text-center mar-none">
                              <button   disabled={this.state.btnloading}
                onClick={this.submitBiodata.bind(this)} 
                type="submit">{this.state.btnloading ? 'loading...':'Send Message'} <i className="flat flaticon-plane" /></button>
                {/* <button onClick={this.submitBiodata.bind(this)} type="submit">submit</button> */}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          );
    }
}
 
export default Contact;