import React from "react";
import { Link } from 'react-router-dom';
function refreshPage(){ 
  window.location.reload(); 
}
class footer3 extends React.Component {
  render() {
    return (
        <div>
            <br/>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        <footer className="py-lg-5 py-4">
        <div className="container py-sm-3">
          <div className="row footer-grids">
            <div className="col-lg-4 mt-4">
              <h3> <a className="navbar-brand px-0 mx-0 mb-4" href="index.html">Homecare International
                </a>
              </h3>
              <p className="mb-3">Homecare Intl with its professional teams has served mail order/catalogue houses, department stores, retail outlets and importers in USA, Europe Presently it offers a unique combination of essential services to market, promote, and facilitate the exports of Pakistani home textiles.</p>
              <h5>Trusted by <span>500+ People</span> </h5>
              <div className="icon-social mt-4">
                <a href="#" className="button-footr">
                  <span className="fa mx-2 fa-facebook" />
                </a>
                <a href="#" className="button-footr">
                  <span className="fa mx-2 fa-twitter" />
                </a>
                <a href="#" className="button-footr">
                  <span className="fa mx-2 fa-dribbble" />
                </a>
                <a href="#" className="button-footr">
                  <span className="fa mx-2 fa-pinterest" />
                </a>
                <a href="#" className="button-footr">
                  <span className="fa mx-2 fa-google-plus" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              <h4 className="mb-4">Quick Links</h4>
              <div className="links-wthree d-flex">
               
                <ul className="list-info-wthree ">
                  
                  <li onClick={refreshPage}><Link to='/'><span className="fa fa-angle-double-right" aria-hidden="true" />Home</Link></li>
                  
                  
                  <li > <Link to='/about' ><span className="fa fa-angle-double-right" aria-hidden="true" />About</Link></li>
                  <li >
             <Link to='/Portfolio'  ><span className="fa fa-angle-double-right" aria-hidden="true" />Products</Link></li>
             <li onClick={refreshPage} ><Link to='/Services' ><span className="fa fa-angle-double-right" aria-hidden="true" />Services</Link></li>
             <li >
            <Link to='/Contact'><span className="fa fa-angle-double-right" aria-hidden="true" />Contact Us</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 mt-4 ad-info">
              <h4 className="mb-4">Contact Info</h4>
              <p><span className="fa fa-map-marker" />•	Plot #260-D Block Small Industrial Estate,<span> Faisalabad - Pakistan.</span></p>
              <p><span className="fa fa-map-marker" />•	Nytorgsbacken house#55 Helsingborg <span> Sweden, Post Code,25245.</span></p>
              <p><span className="fa fa-map-marker" />•	Industrial City, Phase-11,<span>  Jeddah, Saudi Arabia.</span></p>
              <p><span className="fa fa-envelope" /><a href="mailto:info@example.com">info@homecareintl.com</a></p>
            </div>
          </div>
        </div>
      </footer>




      
      <div className="copy_right p-3 d-flex">
        <div className="container">
          <div className="row d-flex">
            <div className="col-lg-9 copy_w3pvt">
              <p className="text-lg-left text-center">© 2019 Homecare International. All rights reserved | Design by 
                <a href="http://w3layouts.com/" target="_blank"> ZS</a></p>
            </div>
            {/* move top */}
            <div className="col-lg-3 move-top text-lg-right text-center">
              <a href="#home" className="move-top">
                <span className="fa fa-angle-double-up mt-3" aria-hidden="true" />
              </a>
            </div>
            {/* move top */}
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default footer3;
