import React, { Component } from 'react';
//import Slider from './components/slide';


import App from '../App' 
//import Header from '../components/head';
import Slider from '../components/slide';

import Slider_6 from '../components/slider6';
import About from '../components/abouts';
//import Services from '../components/service';
import Work from '../components/work';
import Team from '../components/team';
import Portfolio from '../components/port';
import Pricing from '../components/price';
import Counter from '../components/count';
import Blog from '../components/blog';
import Clients from '../components/clients';
import Testimonials from '../components/testimonials';
//import Footer from '../components/footer';

import ContactForm from '../components/contactform';


import Banner from './contactpage_components/contact_banner'
import Contact from './contactpage_components/contact'
import Contactmap from './contactpage_components/contact_map'
import Details from './aboutpage_components/abt_details'
import ACounter from './aboutpage_components/abt_counter'
//import Inquiry from './contactpage_components/sales_inq'
import Inquiry from './contactpage_components/inquiry_form'
import Contacthd from './contactpage_components/contacthd'
import Map from './contactpage_components/contact_map'
const Contactpage = () => (
  <div >
  

     
       <div><Contacthd /></div>
       <div><Contact /></div>
       <div><Map/></div>
       <br/><br/><br/>
</div>
)

export default Contactpage
