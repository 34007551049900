import React from "react";

import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

function refreshPage(){ 
  window.location.reload(); 
}



class contacthd extends React.Component {
  render() {
    return (
    
<div id="home" className="inner-w3pvt-page">
        <div className="overlay-innerpage">
          {/*/top-nav */}
          <div className="top_w3pvt_main container">
            {/*/header */}
            <header className="nav_w3pvt text-center">
              {/* nav */}
              <nav className="wthree-w3ls">
                <div id="logo">
                <h3> <a className="navbar-brand px-0 mx-0" href="" onClick={refreshPage}><Link to='/' className="navbar-brand px-0 mx-0">homecare International</Link>
                    </a>
                    
                  </h3>
                </div>
                <label htmlFor="drop" className="toggle">Menu</label>
                <input type="checkbox" id="drop" />
                <ul className="menu mr-auto">
                  <li onClick={refreshPage}><Link to='/'>Home</Link></li>
                  <li > <Link to='/about' >About</Link></li>
                
                  <li className="active">
             <Link to='/Portfolio'  >Products</Link></li>
                  <li onClick={refreshPage}><Link to='/Services' >Services</Link></li>
                  <li >
            <Link to='/Contact'>Contact</Link></li>
                  <li className="social-icons ml-lg-3"><a href="#" className="p-0 social-icon"><span className="fa fa-facebook-official" aria-hidden="true" />
                      <div className="tooltip">Facebook</div>
                    </a> </li>
                  <li className="social-icons"><a href="#" className="p-0 social-icon"><span className="fa fa-twitter" aria-hidden="true" />
                      <div className="tooltip">Twitter</div>
                    </a> </li>
                  <li className="social-icons"><a href="#" className="p-0 social-icon"><span className="fa fa-instagram" aria-hidden="true" />
                      <div className="tooltip">Instagram</div>
                    </a> </li>
                </ul>
              </nav>
                    </header>
                    <div className="inner-w3pvt-page-info ">
                    <h3 className="align-center text-white">Products</h3>
           </div>
           
          </div>
         
        </div>
      </div>
      
      
    );
}
}

export default contacthd;