import React, { Component } from 'react';
//import Slider from './components/slide';


import App from '../App' 
//import Header from '../components/head';
import Slider from '../components/slide';

import Slider_6 from '../components/slider6';
import About from '../components/abouts';
//import Services from '../components/service';
import Work from '../components/work';
import Team from '../components/team';
import Portfolio from '../components/port';
import Pricing from '../components/price';
import Counter from '../components/count';
import Blog from '../components/blog';
import Clients from '../components/clients';
import Testimonials from '../components/testimonials';
//import Footer from '../components/footer';

import ContactForm from '../components/contactform';


import Banner from './servicespage_components/services_banner'
import Services from './servicespage_components/services'
import Qualities from './aboutpage_components/abt_qualities'
import Details from './aboutpage_components/abt_details'
import ACounter from './aboutpage_components/abt_counter'
import Servhd from './servicespage_components/servhd'
import Serv2 from './servicespage_components/services2'
import Servdet from './servicespage_components/servdetails'

const Servicespage = () => (
  <div >
  

     
       <div><Servhd/></div>
       <div><Serv2/></div>
       <div><Servdet/></div>
       
       <div><Work /></div>
       <br/><br/><br/><br/><br/><br/><br/>
  
</div>
)

export default Servicespage
