import React, { Component } from 'react';


class About_Details extends Component {
   
    render() { 
        return (
            <section id="about-detail" className="index-2">
        <div className="container">
          
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mid-content">
            <div className="row row-reverse">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 img-area"> <img src="img/our-mission.jpg" alt="Our Mission" /> </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-area">
                <div className="section_heading">
                  <div className="number">02</div>
                  <h4>Our <span>Mission</span></h4>
                  <div className="separator left-align ">
                    <ul>
                      <li />
                      <li />
                      <li />
                    </ul>
                  </div>
                </div>
                <div className="description">
                  <p>Is to give our buyers the best service and exceed their expectations. Our goal is to achieve best results: production as per Buyer’s specifications, competitive prices and timely deliveries.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mid-content">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 img-area"> <img src="img/our-vision.jpg" alt="Our Mission" /> </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-area">
                <div className="section_heading">
                  <div className="number">03</div>
                  <h4>Our <span>Vision</span></h4>
                  <div className="separator left-align ">
                    <ul>
                      <li />
                      <li />
                      <li />
                    </ul>
                  </div>
                </div>
                <div className="description">
                  <p>Through the best business practices, observing high personal and corporate standards of morality, integrity and judgment we look forward to continue evolving and growing as a Company.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

          );
    }
}
 
export default About_Details;