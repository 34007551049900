import React, { Component } from 'react';


import Header from './components/head3';
//import Header from './components/header2';

//import Footer from './components/footer';
import Footer from './components/footer3';
//import Footer from './components/Footer2';



//import Header from './components/header'
//import Header from './components/header'
import Main from './components/main'
import Top from './components/topBar/topBar'
const App = () => (
  <div>
  
  
  <Main/>
  <Footer/>
   
  </div>
)

export default App
