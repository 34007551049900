import React from 'react';
//import tc1 from '../img/client_dp1.jpg';
//import tc2 from '../img/client_dp2.jpg';
//import tc3 from '../img/client_dp3.jpg';
import Map from './contactpage_components/contact_map'
class Testimonials extends React.Component {
    
    render() { 
        return ( 
            <div>
            <section id="testimonials" className="gray-bg index-2">
              <div className="container">
                <div className="row row-reverse">
                  <div className="col-lg-6 col-md-6 col-xs-12 content_sec">
                    <div className="section_heading">
                      <h2>What <span>People Say</span></h2>
                      <div className="separator left-align">
                        <ul>
                          <li />
                          <li />
                          <li />
                        </ul>
                      </div>
                    </div>
                    <div className="testimonials-slider owl-four owl-carousel owl-theme">
                      
                      <div className="item">
                        <div className="comment-box">
                          <div className="comment">
                            <p>I would also like to say thank you to all your staff. I love Textile. I'd be lost without Textile. </p>
                          </div>
                        </div>
                        <div className="client_detail">
                          <div className="client_dp"> <img src="https://manufacturer.scdn8.secure.raxcdn.com/textile/wp-content/uploads/sites/6/2018/07/review3-150x150.jpg" alt="Client DP" /> </div>
                          <div className="name_designation">
                            <h5>John S.Gates</h5>
                            <span>Founder</span> </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="comment-box">
                          <div className="comment">
                            <p>
						"Textile has got everything I need. I am really satisfied with my Textile. Textile is the real deal!"</p>
                          </div>
                        </div>
                        <div className="client_detail">
                          <div className="client_dp"> <img src="https://manufacturer.scdn8.secure.raxcdn.com/textile/wp-content/uploads/sites/6/2018/07/review2-150x150.jpg" alt="Client DP" /> </div>
                          <div className="name_designation">
                            <h5>David Garryngton</h5>
                            <span>Founder</span> </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="comment-box">
                          <div className="comment">
                            <p>"Textile impressed me on multiple levels. Needless to say we are extremely satisfied with the results. </p>
                          </div>
                        </div>
                        <div className="client_detail">
                          <div className="client_dp"> <img src="https://manufacturer.scdn8.secure.raxcdn.com/textile/wp-content/uploads/sites/6/2018/07/review1-150x150.jpg" alt="Client DP" /> </div>
                          <div className="name_designation">
                            <h5>Jayson Doremsom</h5>
                            <span>Founder</span> </div>
                        </div>
                      </div>
                     
                     
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
            </section>
            <div><Map/></div>
            <section id="quick-connect" className="index-2">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="newsletter">
                        <div className="section_heading">
                          <h4>Subscribe Our Newsletter</h4>
                          <div className="separator">
                            <ul>
                              <li />
                              <li />
                              <li />
                            </ul>
                          </div>
                          <div className="heading_content">
                            Provide your email address and stay informed with our latest resources
                          </div>
                        </div>
                        <form>
                          <input type="text" placeholder="Enter your Email Id" />
                          <button type="submit">Subscribe</button>
                        </form>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="quick-info">
                        <div className="section_heading">
                          <h4>For Quick Info</h4>
                          <div className="separator">
                            <ul>
                              <li />
                              <li />
                              <li />
                            </ul>
                          </div>
                        </div>
                        <div className="content">
                          <div className="sub-heading">Call Us On</div>
                          <div className="contact-no">+92-304-7133321</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
         );
    }
}
 
export default Testimonials;