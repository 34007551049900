import React from "react"; 

class team extends React.Component {
  render() {
    return (
      <section id="our_team" className="index-2">
        <div className="container">
          <div className="section_heading">
            <h2>
            Meet  <span>the Dream Team</span>
            </h2>
            <div className="separator">
              <ul>
                <li />
                <li />
                <li />
              </ul>
            </div>
            <div className="heading_content">
            Textile inspires and equips people to accelerate sustainable practices in the textile value chain. We focus on minimizing the harmful impacts of the global textile industry and maximizing its positive effects.
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-xs-12 content_sec">
            <img src="img/team.jpg"/>
          </div>
        </div>
        <div className="clearfix" />
      </section>
    );
  }
}

export default team;
