import React, { Component } from 'react';


class Protex extends Component {
   
    render() { 
        return (
<section className="projects py-5" id="gallery">
<div className="container py-md-5">
  <h3 className="tittle-w3ls text-left mb-5"><span className="pink">Stunning</span> Products</h3>
  <div className="row news-grids mt-md-5 mt-4 text-center">
    <div className="col-md-4 gal-img">
      <a href="#gal1"><img src="img/bed1.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>Bedding<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal2"><img src="img/bed2.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>bedding<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal3"><img src="img/bed3.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>Bedding<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal4"><img src="img/bed4.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>bedding <span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal5"><img src="img/bed5.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>bedding<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal6"><img src="img/bed6.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>bedding<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal7"><img src="img/bed7.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>bedding<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal8"><img src="img/bed8.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>bedding<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal9"><img src="img/bed9.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>bedding<span className="decription"></span></h5>
      </div>
    </div>










    <div className="col-md-4 gal-img">
      <a href="#gal10"><img src="img/kitchen1.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>kitchen apparel<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal11"><img src="img/kitchen2.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>kitchen apparel<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal12"><img src="img/kitchen3.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>kitchen apparel<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal13"><img src="img/kitchen4.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>kitchen apparel <span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal14"><img src="img/kitchen5.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>kitchen apparel<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal15"><img src="img/kitchen6.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>kitchen apparel<span className="decription"></span></h5>
      </div>
    </div>





    <div className="col-md-4 gal-img">
      <a href="#gal16"><img src="img/terry1.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>terry Products<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal17"><img src="img/terry2.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>terry Products<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal18"><img src="img/terry3.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>terry Products<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal19"><img src="img/terry4.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>terry Products <span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal20"><img src="img/terry5.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>terry Products<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal21"><img src="img/terry6.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>terry Products<span className="decription"></span></h5>
      </div>
    </div>








    
    <div className="col-md-4 gal-img">
      <a href="#gal22"><img src="img/other1.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>other Products<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal23"><img src="img/other2.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>other Products<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal24"><img src="img/other3.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>other Products<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal25"><img src="img/other4.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>other Products <span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal26"><img src="img/other5.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>other Products<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal27"><img src="img/other6.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>other Products<span className="decription"></span></h5>
      </div>
    </div>


    <div className="col-md-4 gal-img">
      <a href="#gal28"><img src="img/other7.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>other Products<span className="decription"></span></h5>
      </div>
    </div>
    <div className="col-md-4 gal-img">
      <a href="#gal29"><img src="img/other8.jpg" alt="w3pvt" className="img-fluid" /></a>
      <div className="gal-info">
        <h5>other Products<span className="decription"></span></h5>
      </div>
    </div>










    
   
  </div>
  {/* popup*/}
  <div id="gal1" className="pop-overlay">
    <div className="popup">
      <img src="img/bed1.jpg" alt="Popup Image" className="img-fluid" />
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal2" className="pop-overlay">
    <div className="popup">
      <img src="img/bed2.jpg" alt="Popup Image" className="img-fluid" />
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal3" className="pop-overlay">
    <div className="popup">
      <img src="img/bed3.jpg" alt="Popup Image" className="img-fluid" />
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup3 */}
  {/* popup*/}
  <div id="gal4" className="pop-overlay">
    <div className="popup">
      <img src="img/bed4.jpg" alt="Popup Image" className="img-fluid" />
      <h5>View Project</h5>
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal5" className="pop-overlay">
    <div className="popup">
      <img src="img/bed5.jpg" alt="Popup Image" className="img-fluid" />
      <h5 className="mt-3">View Project</h5>
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal6" className="pop-overlay">
    <div className="popup">
      <img src="img/bed6.jpg" alt="Popup Image" className="img-fluid" />
      <h5 className="mt-3">View Project</h5>
      <p>Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  <div id="gal7" className="pop-overlay">
    <div className="popup">
      <img src="img/bed7.jpg" alt="Popup Image" className="img-fluid" />
      <h5 className="mt-3">View Project</h5>
      <p>Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>


  <div id="gal8" className="pop-overlay">
    <div className="popup">
      <img src="img/bed8.jpg" alt="Popup Image" className="img-fluid" />
      <h5 className="mt-3">View Project</h5>
      <p>Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>

  <div id="gal9" className="pop-overlay">
    <div className="popup">
      <img src="img/bed9.jpg" alt="Popup Image" className="img-fluid" />
      <h5 className="mt-3">View Project</h5>
      <p>Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>






  <div id="gal10" className="pop-overlay">
    <div className="popup">
      <img src="img/kitchen1.jpg" alt="Popup Image" className="img-fluid" />
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal11" className="pop-overlay">
    <div className="popup">
      <img src="img/kitchen2.jpg" alt="Popup Image" className="img-fluid" />
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal12" className="pop-overlay">
    <div className="popup">
      <img src="img/kitchen3.jpg" alt="Popup Image" className="img-fluid" />
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup3 */}
  {/* popup*/}
  <div id="gal13" className="pop-overlay">
    <div className="popup">
      <img src="img/kitchen4.jpg" alt="Popup Image" className="img-fluid" />
      <h5>View Project</h5>
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal14" className="pop-overlay">
    <div className="popup">
      <img src="img/kitchen5.jpg" alt="Popup Image" className="img-fluid" />
      <h5 className="mt-3">View Project</h5>
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal15" className="pop-overlay">
    <div className="popup">
      <img src="img/kitchen6.jpg" alt="Popup Image" className="img-fluid" />
      <h5 className="mt-3">View Project</h5>
      <p>Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
 









  <div id="gal16" className="pop-overlay">
    <div className="popup">
      <img src="img/terry1.jpg" alt="Popup Image" className="img-fluid" />
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal17" className="pop-overlay">
    <div className="popup">
      <img src="img/terry2.jpg" alt="Popup Image" className="img-fluid" />
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal18" className="pop-overlay">
    <div className="popup">
      <img src="img/terry3.jpg" alt="Popup Image" className="img-fluid" />
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup3 */}
  {/* popup*/}
  <div id="gal19" className="pop-overlay">
    <div className="popup">
      <img src="img/terry4.jpg" alt="Popup Image" className="img-fluid" />
      <h5>View Project</h5>
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal20" className="pop-overlay">
    <div className="popup">
      <img src="img/terry5.jpg" alt="Popup Image" className="img-fluid" />
      <h5 className="mt-3">View Project</h5>
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal21" className="pop-overlay">
    <div className="popup">
      <img src="img/terry6.jpg" alt="Popup Image" className="img-fluid" />
      <h5 className="mt-3">View Project</h5>
      <p>Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
 






  <div id="gal22" className="pop-overlay">
    <div className="popup">
      <img src="img/other1.jpg" alt="Popup Image" className="img-fluid" />
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal23" className="pop-overlay">
    <div className="popup">
      <img src="img/other2.jpg" alt="Popup Image" className="img-fluid" />
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal24" className="pop-overlay">
    <div className="popup">
      <img src="img/other3.jpg" alt="Popup Image" className="img-fluid" />
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup3 */}
  {/* popup*/}
  <div id="gal25" className="pop-overlay">
    <div className="popup">
      <img src="img/other4.jpg" alt="Popup Image" className="img-fluid" />
      <h5>View Project</h5>
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal26" className="pop-overlay">
    <div className="popup">
      <img src="img/other5.jpg" alt="Popup Image" className="img-fluid" />
      <h5 className="mt-3">View Project</h5>
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal27" className="pop-overlay">
    <div className="popup">
      <img src="img/other6.jpg" alt="Popup Image" className="img-fluid" />
      <h5 className="mt-3">View Project</h5>
      <p>Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
 
 {/* popup*/}
 <div id="gal28" className="pop-overlay">
    <div className="popup">
      <img src="img/other7.jpg" alt="Popup Image" className="img-fluid" />
      <h5 className="mt-3">View Project</h5>
      <p className="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
  {/* //popup */}
  {/* popup*/}
  <div id="gal29" className="pop-overlay">
    <div className="popup">
      <img src="img/other8.jpg" alt="Popup Image" className="img-fluid" />
      <h5 className="mt-3">View Project</h5>
      <p>Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
      <a className="close" href="#gallery">×</a>
    </div>
  </div>
 




</div>
</section>
    );
}
}

export default Protex;