import React, { Component } from 'react';
//import Slider from './components/slide';
import '../App'


import Header from '../components/head';
import Slider from '../components/slide';
import Slider3 from '../components/slider3';
import Slider_6 from '../components/slider6';
import About from '../components/abouts';
import Services from '../components/service';
import Work from '../components/work';
import Team from '../components/team';
//import Portfolio from '../components/port';
import Pricing from '../components/price';
import Counter from '../components/count';
import Blog from '../components/blog';
import Clients from '../components/clients';
import Testimonials from '../components/testimonials';
import Footer from '../components/footer';

import ContactForm from '../components/contactform';

//import '../css/owl.carousel.min.css';
//import '../css/owl.theme.default.min.css';
//import Portfolio from '../components/portfolio_gallery/portfolio';

import Portfolio from '../components/port_test/t2';
import SN from '../components/slidernew';
import Box3 from '../components/3box'
import Test3 from '../components/test3'
import Abtcount from './aboutpage_components/abt_counter'
import Map from './contactpage_components/contact_map'
import Header3 from './header4';

const Home = () => (
  <div >
   <div><Header3/></div>
  <div><Box3/></div>
  <div><About/></div>
  


  <div><Clients/></div>
 <br/><br/> <br/><br/><br/><br/><br/><br/>
 <br/> <br/> <br/><br/><div><Abtcount/></div>
  
    <div><Testimonials/></div>
    
    
</div>
)

export default Home
