import React from "react";
import logo from '../img/logo-6.png'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import Test3 from './test3'
function refreshPage(){ 
  window.location.reload(); 
}



class head4 extends React.Component {
  render() {
    return (
      
        <div id="home">
        {/*/top-nav */}
        <div className="top_w3pvt_main container">
          {/*/header */}
          <header className="nav_w3pvt text-center ">
            {/* nav */}
            <nav className="wthree-w3ls">
              <div id="logo">
              <h3> <a className="navbar-brand px-0 mx-0" href="" onClick={refreshPage}><Link to='/' className="navbar-brand px-0 mx-0">homecare International</Link>
                    </a>
                    
                  </h3>
              </div>
              <label htmlFor="drop" className="toggle">Menu</label>
              <input type="checkbox" id="drop" />
              <ul className="menu mr-auto">
                <li className="active"  onClick={refreshPage} ><Link to='/'>Home</Link></li>
                <li>
            <Link to='/about' >About</Link></li>
            <li >
             <Link to='/Portfolio'  >Products</Link></li>
                <li  onClick={refreshPage} ><Link to='/Services' >Services</Link></li>
                
                <li  >
            <Link to='/Contact'>Contact</Link></li>
                <li className="social-icons ml-lg-3"><a href="#" className="p-0 social-icon"><span className="fa fa-facebook-official" aria-hidden="true" />
                    <div className="tooltip">Facebook</div>
                  </a> </li>
                <li className="social-icons"><a href="#" className="p-0 social-icon"><span className="fa fa-twitter" aria-hidden="true" />
                    <div className="tooltip">Twitter</div>
                  </a> </li>
                <li className="social-icons"><a href="#" className="p-0 social-icon"><span className="fa fa-instagram" aria-hidden="true" />
                    <div className="tooltip">Instagram</div>
                  </a> </li>
              </ul>
            </nav>
            
          </header>
        
        </div>
      
      {/* banner slider */}
      <div id="homepage-slider" className="st-slider">
        <input type="radio" className="cs_anchor radio" name="slider" id="play1" defaultChecked />
        <input type="radio" className="cs_anchor radio" name="slider" id="slide1" />
        <input type="radio" className="cs_anchor radio" name="slider" id="slide2" />
        <input type="radio" className="cs_anchor radio" name="slider" id="slide3" />

        <div className="images">
          <div className="images-inner">
            <div className="image-slide">
              <div className="banner-w3pvt-1">
                <div className="overlay-w3ls">
                </div>
              </div>
            </div>
            <div className="image-slide">
              <div className="banner-w3pvt-2">
                <div className="overlay-w3ls">
                </div>
              </div>
            </div>
            <div className="image-slide">
              <div className="banner-w3pvt-3">
                <div className="overlay-w3ls">
                </div>
              </div>
            </div>
            <div className="image-slide">
              <div className="banner-w3pvt-4">
                <div className="overlay-w3ls">
                </div>
              </div>
            </div>
            <div className="image-slide">
              <div className="banner-w3pvt-5">
                <div className="overlay-w3ls">
                </div>
              </div>
            </div>
            
            
            
          </div>
        </div>
        <div className="labels">
          <div className="fake-radio">
            <label htmlFor="slide1" className="radio-btn" />
            <label htmlFor="slide2" className="radio-btn" />
            <label htmlFor="slide3" className="radio-btn" />
          </div>
        </div>
        {/* banner-hny-info */}
        <div className="banner-hny-info">
          
        <h3 >The Largest Sourcing & Inspection Company in Pakistan</h3>
              <div className="content " align="center"> <b>Our Products-</b><b> Fabric , Bed Linen , Bath Linen , Hospital Linen , Hotel Linen , Kitchen Linen.</b></div>
            <div className="top-buttons mx-auto text-center mt-md-5 mt-3">
              <a href="single.html" className="btn more mr-2 " onClick={refreshPage} >
              
            <Link to='/about' className="text-white" >Read More  </Link></a>

              <a href="contact.html" className="btn" onClick={refreshPage}>
            <Link to='/Contact'className="text-white">Contact Us</Link> </a>
                      </div>
          <div className="d-flex hny-stats-inf">
            <div className="col-md-4 stats_w3pvt_counter_grid mt-3">
              <div className="d-md-flex justify-content-center">
                <h5 className="counter">2568</h5>
                <p className="para-w3pvt">Designs</p>
              </div>
            </div>
            <div className="col-md-4 stats_w3pvt_counter_grid mt-3">
              <div className="d-md-flex justify-content-center">
                <h5 className="counter">1900</h5>
                <p className="para-w3pvt"> Projects</p>
              </div>
            </div>
            <div className="col-md-4 stats_w3pvt_counter_grid mt-3">
              <div className="d-md-flex justify-content-center">
                <h5 className="counter">899</h5>
                <p className="para-w3pvt">Clients</p>
              </div>
            </div>
          </div>
        </div>
        {/* //banner-hny-info */}
      </div>
      {/* //banner slider */}
      {/* //banner */}
      </div>
    );
  }
}

export default head4;
