import React, { Component } from 'react';


class Service2 extends Component {
   
    render() { 
        return (
            <section id="about-us-inner" className="index-2">
            <div className="container">
              <h2 className="d-none">Heading</h2>
              <div className="col-lg-12 col-md-12 col-xs-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 img-area"> <img className="img-area" src="img/s1.jpg" alt="About" /> </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-area">
                    <div className="about-heading"><h4>General <span>Responsibilities</span></h4></div>
                    <p class="text-justify">1. Arranging Buyers’ visits and accompanying them to suppliers’ factories while in Pakistan. Alternately, Homecare International coordinates with the Buyers/suppliers for meetings at Buyer’s Head Office or in convenient locations such as Dubai, Dhaka etc.

<br/>2. Sourcing the best products at the most competitive prices as per the Buyers requirements.

<br/>3. Correspondence with Principal and suppliers to ensure smooth communications.

<br/>4. Development and Order Sampling.

<br/>5. Follow-up of progress and for timely deliveries of the orders.

<br/>6. Informing the Buyers about the market trends in Pakistan.

<br/><br/>7. Exclusively computerized system of purchase and follow up of materials, Order and Production Follow up, Production Audits analyzed to ensure accuracy and avoid eleventh hour surprises.

<br/><br/>8. Professional Services.
</p>
    
    
    
    
                  </div>
    
                </div>
              </div>
            </div>
          </section>
          );
        }
    }
     
    export default Service2;