import React from "react";

class clients extends React.Component {
  render() {
    return (
      <section id="client" className="index-2">
        <div className="container">
          <div className="section_heading">
            <h2>Our Clients</h2>
            <div className="separator">
              <ul>
                <li />
                <li />
                <li />
              </ul>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="team-slider owl-three owl-carousel owl-theme "   >
             

              <div className="item">
                {" "}
                <img src="img/partners_item_2.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
              <div className="item">
                {" "}
                <img src="img/partners_item_4.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
              
              <div className="item">
                {" "}
                <img src="img/partners_item_5.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
             
             

            </div>
            <div className="left-tri" />
            <div className="right-tri" />
          </div>


        </div>
        <div className="clearfix" />
        <div className="overlay" />
      </section>
    );
  }
}

export default clients;
