import React from "react";
import logo from '../img/logo-6.png'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import Test3 from './test3'
function refreshPage(){ 
  window.location.reload(); 
}



class head extends React.Component {
  render() {
    return (
      <div>
      <div className="header-top contact_info">
        <div className="container">
          <div className="col-lg-12 col-md-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="follow_us_on">
                  <ul>
                    <li>
                      <a href="javascript:void(0)" title="Facebook" href="https://www.facebook.com">
                        <i className="flat flaticon-facebook-logo" color="red"/>
                      </a>
                    </li>
                    <li className="separator-pipesign white" />
                    <li>
                      <a href="javascript:void(0)" title="Twitter">
                        <i className="flat flaticon-twitter" />
                      </a>
                    </li>
                    <li className="separator-pipesign white" />
                    <li>
                      <a href="javascript:void(0)" title="Google">
                        <i className="flat flaticon-google-plus-logo" />
                      </a>
                    </li>
                    <li className="separator-pipesign white" />
                    <li>
                      <a href="javascript:void(0)" title="Linkedin">
                        <i className="flat flaticon-linkedin-logo" />
                      </a>
                    </li>
                    <li className="separator-pipesign white" />
                    <li>
                      <a href="javascript:void(0)" title="Instagram">
                        <i className="flat flaticon-instagram" />
                      </a>
                    </li>
                    
                   
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mail-call">
                <div className="mail-to">
                  <i className="flat flaticon-mail-black-envelope-symbol" />
                  
                  <a href="mailto:info@wizmen.net" className="text-white">info@wizmen.net</a>
                </div>
                <span className="separator-pipesign white" />
                <div className="call-on ">
                  <i className="flat flaticon-phone-receiver"> </i>
                 
                            <a href="tel:+92-321-9416913" className="text-white" > +92-321-9416913 </a>
                </div>
            
              </div>
              
              
            </div>
           
          </div>
        </div>
       
      </div>
      
      <header className="header index-2">
      
<div className="container">

 <nav className="navbar navbar-expand-lg">

        <div onClick={refreshPage}>
       
         <Link to='/' ><img src={logo} alt="wizmen systems" /></Link>
       </div>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#nav-content" aria-controls="nav-content"
        aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
          <span className="navbar-toggler-icon"></span>
          <span className="navbar-toggler-icon"></span>
        </button>
     
         <div className="main-menu collapse navbar-collapse" id="nav-content">
          <ul className="navbar-nav">
          <li className="nav-item active"  onClick={refreshPage}>
             <Link to='/'  >Home</Link>
             
            </li>
             <li className="nav-item  "  onClick={refreshPage} >
            <Link to='/about' >About</Link>
              
            </li>
             <li className="nav-item " onClick={refreshPage} >
             <Link to='/Services' >Services</Link>
              
            </li>
            <li className="nav-item " onClick={refreshPage}>
             <Link to='/Portfolio'  >Portfolio</Link>
             
            </li>
           
             
            <li className="nav-item" onClick={refreshPage}>
              <Link to='/Career'  >Career</Link>
 
            </li>
             <li className="nav-item"  onClick={refreshPage}>
            <Link to='/Contact'>Contact Us</Link>
              
            </li>
            
          </ul>
        

                            
        </div>
      </nav>
</div>
</header>
      </div>
    );
  }
}

export default head;
