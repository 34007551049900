import React from "react";




class about extends React.Component {



  render() {

 
    
    return (
      <section id="about-us" className="index-2">
        <div className="container">
          <div className="section_heading">
          <h1>Company  <span>Preface</span></h1>
            <div className="separator">
              <ul>
                <li />
                <li />
                <li />
              </ul>
            </div>
            <div className="heading_content" >
            <p  > Homecare Intl with its professional teams has served mail order/catalogue houses, department stores, retail outlets and importers in USA, Europe Presently it offers a unique combination of essential services to market, promote, and facilitate the exports of Pakistani home textiles.
           </p> </div>
          </div>
          <div className="col-lg-12 col-md-12 col-xs-12 content_sec">
            <div className="row row-safari">
              <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12 img-area">
                {" "}
                <img
                  className="img_sec"
                  src="img/t1.jpeg"
                  alt="About"
                />{" "}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-area">
              <h5>Why We Are Different</h5>
                <p className="text-justify">
                We have deep business interaction with manufactures in major cities of Pakistan and outside Pakistan so we are quite flexible and confident to do any kind of textile products within agreed time frame and quality entirely as per given order specifications.
               </p>
                <h5>Our Resource taring policy</h5>
            <p className="text-justify">
            Homecare International has special software programmes to control all stages of the orders, which results in delivery of quality products as per Buyer’s specifications.
            </p>
                <a className="defualt-button view_more" href="about-us.html">
                  View More
                </a>{" "}
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="qualities col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 column">
                <div className="icon">
                  <i className="flat flaticon-reward" />
                </div>
                <div className="description">
              <h5>We are Professional</h5>
              <p  >Our company has divided in two textile divisions , operating separately under the well educated and experienced production and quality control staff.</p>
            </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 column">
                <div className="icon">
                  <i className="flat flaticon-enterprise" />
                </div>
                <div className="description">
                  <h5>Number 1 Company</h5>
                  <p >
                  Homecare is working as Multi-Functional company in the field of all kind of Yarn, Fabrics, Home textiles and Textile Apparels. The Chief Executive of the Company has 25 Years Of Textile Experience on its back in dealing all categories of textile products.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 column">
                <div className="icon">
                  <i className="flat flaticon-certificate" />
                </div>
                <div className="description">
                  <h5>Licensed and Certified</h5>
                  <p > </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 column">
                <div className="icon">
                  <i className="flat flaticon-working-team" />
                </div>
                <div className="description">
                <h5>Leading Provider</h5>
              <p >Our normal lead time for garments are 90 days and for fabrics and home textiles 60 days but in special case on buyers demands. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default about;
