import React, { Component } from 'react';


class Serv_Details extends Component {
   
    render() { 
        return (
            <section id="about-detail" className="index-2">
        <div className="container">
          
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mid-content">
            <div className="row row-reverse">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 img-area"> <img src="img/s2.jpg" alt="Our Mission" /> </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-area">
                <div className="section_heading">
                  <div className="number">02</div>
                  <h4>Supplier <span>Sourcing</span></h4>
                  <div className="separator left-align ">
                    <ul>
                      <li />
                      <li />
                      <li />
                    </ul>
                  </div>
                </div>
                <div className="description">
                  <p class="text-justify">Homecare International offers its services to their foreign Principals, representing them as their Buying Agent in Pakistan. Homecare works with Pakistani suppliers who are from major industrial groups with vertical setups, well equipped, having a strong financial base, located in Karachi, Lahore, Faisalabad and Multan; these suppliers are selected through our vigorous evaluation process. We constantly assess the manufacturing and management capabilities of suppliers by evaluation of plant, machinery and equipment, as well as labor skills, social compliance, financial strength and management style of the company.<br/>

While sourcing a supplier, Homecare International gives priority to their past performance, market reputation, evaluation of their facilities, staff and social compliance standards. We have developed a wide base of competent supplier, based on their reliability and performance, thus strengthening our position to negotiate on our customers’ behalf.</p>
                </div>
                
              </div>
              
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mid-content">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 img-area"> <img src="img/s3.jpg" alt="Our Mission" /> </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-area">
                <div className="section_heading">
                  <div className="number">03</div>
                  <h4>Quality <span> Control / Policy</span></h4>
                  <div className="separator left-align ">
                    <ul>
                      <li />
                      <li />
                      <li />
                    </ul>
                  </div>
                </div>
                <div className="description">
                  <p class="text-justify">As per Buyers’ requirement, Homecare International audits its Suppliers’ facilities to ensure they pass their Social Compliance policy which includes:Attention to Details’ and ‘No Surprises’ is our forte when it comes to quality control. Our Quality Assurance Inspectors visit the manufacturing sites at various stages of production to ensure that Quality and specifications conform to our Buyers’ orders and mutually agreed standards. When the goods are finally ready, a Final Inspection (according to buyer AQL standard) is conducted by our QA team.<br/>

To serve our buyers and give more comprehensive services we have installed a laboratory for in-house testing in which we conduct tests according to ISO & buyer testing specification for Shrinkage, Torque, Color fastness on different fibers, crocking, shade variations etc….<br/>

Homecare International has special software programmes to control all stages of the orders, which results in delivery of quality products as per Buyer’s specifications.
</p>
                </div>
              </div>
            </div>
          </div>
           
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mid-content">
            <div className="row row-reverse">
          
              <div className="">
                <div className="section_heading">
                  <div className="number">04</div>
                  <h4>Inspection <span></span></h4>
                  <div className="separator left-align ">
                    <ul>
                      <li />
                      <li />
                      <li />
                    </ul>
                  </div>
                </div>
                <div className="description">
                  <p class="text-justify">Homecare Int'l Sourcing, Quality Inspection & Textile Buying House Homecare International is an independent Textile Sourcing, Indenters, Buying House & Quality Inspection provider of Yarn, Home Textile, Fabrics, Knitted Garments, Towels, having worldwide recognition due to its reliable services. We are Pakistan based organization, providing exclusive services of quality inspection, quality assurance, quality audit, third party inspection, production check for our clients, retailers, importers, trading partners and manufacturers. Our qualified inspection team members help to assess product quality, equal to the standard of international level by using different methodologies of product evaluation. Our quality inspectors are specialized in quality control inspection and qualified in providing quality inspection and audit services for Yarn, Garments, Home Textiles, Fabrics, Towels, etc. Our inspection team is well-experienced and well-qualified technically to undertake multiple inspection processes to assure the quality of your merchandise.<br/>
                  Homecare specializes in Home textile, Fabrics and Apparel Garments Creative Textile Sourcing is set up to detect Product quality problems at an early production stage to prevent foreseeable fabric defects in mass production, thereby ensuring on time delivery, Homecare Product inspection team covers a full range of Product segments - woven, knit, luxury, denim, heavy & light weight fabrics etc.<br/>
                  Homecare is able to serve as your eyes and advisers on Product issues. We perform Product inspections utilizing the internationally accepted AQL 2.5 (ISO standards) Inspection System. However, we can also inspect Product using different methodologies such as the Demerit Product System or tailor made systems designed to suit our client’s specific quality requirements We are able to utilize different Product sampling methods and operate Rejection Methods and Rates as defined by our clients and dependent on the end use and fabric type.We have an extensive Product inspection database compiled through random sampling. Home Textile & Apparel Garments. Homecare focuses on different types of Home Textile and Apparel Garments by applying specific technical quality and production improvement know-how we specialize in<br/>
                  * BED LINEN.<br/>
* BATH LINEN.<br/>
* KITCHEN LINEN.<br/>
* APPAREL GARMENTS.<br/>
* FEBRICS
</p>
</div>
                
              </div>
              
            </div>
          </div>
        </div>
      </section>

          );
    }
}
 
export default Serv_Details;